// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  api: {
    url: 'https://apis.dev.auckland.ac.nz/password-management/v1/',
  },
  epr: {
    path: 'https://iam.dev.auckland.ac.nz',
  },
  auth: {
    codeChallengeMethod: 'S256',
    cognitoAwsRegion: 'ap-southeast-2',
    cognitoClientId: '51lb64org4e0q448b4djl4sg07',
    cognitoDomain: 'uoapool-nonprod',
    cognitoUserPoolId: 'ap-southeast-2_gtuqqgIIq',
    scopes: 'openid profile',
    redirectUri: 'https://password.dev.auckland.ac.nz',
    logoutUri: 'https://password.dev.auckland.ac.nz/logout',
  },
  privateUrlKeyWords: {
    whoNeedBearerToken: [{url:'apis.dev.auckland.ac.nz/password-management/v1',optional:true, publicPaths:['/validate', '/reset']}],
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
