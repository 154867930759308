import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard, LoginSuccessGuard } from '@uoa/auth';
import {GuardAdminOnlyLogin} from './auth/guard/GuardAdminOnlyLogin';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'landing',
    pathMatch: 'full',
  },
  {
    path: 'admin',
    loadChildren: () => import('./pages/admin/admin.module').then((m) => m.AdminPageModule),
    canActivate: [AuthGuard,GuardAdminOnlyLogin],
  },
  {
    path: 'reset',
    loadChildren: () => import('./pages/self-reset/self-reset.module').then((m) => m.SelfResetPageModule),
    canActivate: [],
  },
  {
    path: 'change',
    loadChildren: () => import('./pages/self-change-password/self-change-password.module').then((m) => m.SelfChangePasswordPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'change/success',
    loadChildren: () => import ('./pages/self-change-finish/self-change-finish.module').then((m) => m.SelfChangeFinishPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'error/:errorCode',
    loadChildren: () => import('./error/error-routing.module').then((m) => m.ErrorPageRoutingModule),
  },
  {
    path: 'landing',
    canActivate: [LoginSuccessGuard],
    loadChildren: () => import('./pages/landing/landing.module').then((m) => m.LandingPageModule),
  },
  { path: '**', redirectTo: 'landing' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
